<template>
    <div class="main">
        <choose-model @onModelChosen="setModelId" @onBrandChosen="setBrandId" :key="componentKey" />
        <div class="spec">
            <vs-row>
                <vs-col vs-lg="6" vs-sm="12">
                    <div class="drop-down">
                        <label for="specs">Choose Category</label>
                        <select id="specs" v-model="category_id">
                            <option value="0" selected disabled>Choose Category</option>
                            <option
                                v-for="(category, index) in categoriesOfModel"
                                :value="category.id"
                                :key="index"
                            >{{category.name_en}}</option>
                        </select>
                    </div>
                </vs-col>
            </vs-row>
        </div>
        <div class="spec">
            <vs-row>
                <vs-col vs-lg="6" vs-sm="12">
                    <div class="drop-down">
                        <label for="specs">Choose Spec</label>
                        <select id="specs" v-model="spec_id" :disabled="invalidCategory">
                            <option value="0" selected disabled>Choose Spec</option>
                            <option
                                v-for="(spec, index) in specs"
                                :value="spec.id"
                                :key="index"
                            >{{spec.name_en}}</option>
                        </select>
                    </div>
                </vs-col>
                <vs-col vs-lg="6" vs-sm="12">
                    <div style="visibility: hidden">
                        <label />
                    </div>
                    <div class="flex">
                        <vs-button
                            icon-pack="feather"
                            icon="icon-save"
                            :disabled="invalidSpec"
                            @click="getPayload"
                        >Save</vs-button>
                        <vs-button
                            type="border"
                            class="ml-5 mr-10"
                            color="primary"
                            @click="resetValues"
                        >Reset</vs-button>
                    </div>
                </vs-col>
            </vs-row>
            <vs-prompt
                @cancel="val=''"
                @accept="getInputValue"
                @close="close"
                :active.sync="activePrompt"
            >
                <div class="con-exemple-prompt">
                    Enter Text Value
                    <vs-input placeholder="Text Value" v-model="val" />
                </div>
            </vs-prompt>
            <vs-prompt
                @cancel="val=''"
                @accept="confirmEditData"
                @close="close"
                :active.sync="activePrompt2"
            >
                <div class="con-exemple-prompt">
                    Enter Text Value
                    <vs-input placeholder="Text Value" v-model="val" />
                </div>
            </vs-prompt>
        </div>

        <div id="data-list-list-view" class="data-list-container mt-12">
            <vs-table ref="table" :sst="true" :data="specsOfCategory">
                <!--        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">-->

                <!--          <div class="flex flex-wrap-reverse items-center data-list-btn-container">-->

                <!--          </div>-->

                <!--          &lt;!&ndash; ITEMS PER PAGE &ndash;&gt;-->
                <!--          <table-header-->
                <!--            :current-page="currentPage"-->
                <!--            :items-per-page="itemsPerPage"-->
                <!--            :length="items.length"-->
                <!--            :queried-items="queriedItems"-->
                <!--            @onChangeItemsPerPage="itemsPerPage = $event"-->
                <!--          />-->
                <!--        </div>-->

                <template slot="thead">
                    <vs-th sort-key="name_en">English Name</vs-th>
                    <vs-th sort-key="name_ar">Arabic Name</vs-th>
                    <vs-th sort-key="type">Type</vs-th>
                    <vs-th sort-key="value">Value</vs-th>
                    <vs-th>Action</vs-th>
                </template>

                <template slot-scope="{data}">
                    <tbody>
                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <string-row :data="tr.name_en" />
                            <string-row :data="tr.name_ar" />
                            <string-row :data="tr.type === 'bool'? 'CheckBox' : 'Text'" />
                            <string-row :data="getValue(tr.value)" />
                            <actions-row
                                :editable="tr.type === 'text'"
                                @onEditClicked="editData(tr, indextr)"
                                @onDeleteClicked="deleteData(tr.pivot_id, indextr)"
                            />
                        </vs-tr>
                    </tbody>
                </template>
            </vs-table>
        </div>
        <!--    <vs-pagination :total="totalItems" v-model="page"></vs-pagination>-->
    </div>
</template>

<script>
import ChooseModel from "../../../../../components/custom/choose-model";
import breadcrumb from "../../../../../components/general/breadcrumb";
import TableHeader from "../../../../../components/table/table-header";
import StringRow from "../../../../../components/table/rows/string-row";
import ImageRow from "../../../../../components/table/rows/image-row";
import StatusRow from "../../../../../components/table/rows/status-row";
import ActionsRow from "../../../../../components/table/rows/actions-row";
import AddNewButton from "../../../../../components/form/addnew-button";

export default {
    name: "model-specs-list",
    components: {
        ChooseModel,
        ActionsRow,
        StatusRow,
        ImageRow,
        StringRow,
        TableHeader,
        breadcrumb,
        AddNewButton
    },
    data() {
        return {
            val: "",
            category_id: "0",
            spec_id: "0",
            specs: [],
            specsOfCategory: [],
            categories: [],
            invalidCategory: true,
            items: [],
            itemsPerPage: 20,
            isMounted: false,
            page: 1,
            totalItems: 0,
            query: "",
            sortKey: null,
            sortActive: null,
            activePrompt: false,
            activePrompt2: false,
            componentKey: 0,
            model_id: "0",
            categoriesOfModel: [],
            pivot_id: "0"
        };
    },
    methods: {
        close() {
            this.activePrompt = false;
        },
        resetValues() {
            this.category_id = "0";
            this.spec_id = "0";
            this.specsOfCategory = [];
            this.val = "";
            this.componentKey++;
        },
        editData(data, index) {
            this.val = data.value;
            this.pivot_id = data.pivot_id;
            this.index = index;
            this.activePrompt2 = true;
        },
        confirmEditData() {
            let payload = {
                id: this.pivot_id,
                value: this.val
            };

            this.$httpCars
                .post("categories/updatespec", payload)
                .then(r => {
                    console.log(r);
                    this.$vs.notify({
                        title: "Success",
                        text: "Spec is updated Successfully",
                        color: "success"
                    });
                    this.specsOfCategory[this.index].value = this.val;
                })
                .catch(() => {
                    //console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        text: "Couldn't updated Spec!",
                        color: "danger"
                    });
                });

            this.val = "";
            this.pivot_id = "";
            this.index = "";
        },
        deleteData(id, index) {
            this.$httpCars
                .get(`categories/deletespec/${id}`)
                .then(r => {
                    console.log(r);
                    this.$vs.notify({
                        title: "Success",
                        text: "Spec is deleted Successfully",
                        color: "success"
                    });
                    this.specsOfCategory.splice(index, 1);
                })
                .catch(() => {
                    //console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        text: "Couldn't Delete Spec!",
                        color: "danger"
                    });
                });
        },
        handleSearch(query) {
            this.query = query;
        },
        handleSort(key, active) {
            this.sortKey = key;
            this.sortActive = active === "asc" ? 1 : 0;
        },
        getPayload() {
            for (let i in this.specsOfCategory) {
                if (
                    this.specsOfCategory[i].id === this.spec_id &&
                    this.specsOfCategory[i].value !== "0"
                ) {
                    this.$vs.notify({
                        title: "Not Allowed",
                        text: "This spec already exists in this category!",
                        color: "danger"
                    });
                    return;
                }
            }

            let payload;

            for (let i in this.specs) {
                if (this.specs[i].id === this.spec_id) {
                    if (this.specs[i].type === "bool") {
                        payload = {
                            category_id: this.category_id,
                            spec_id: this.spec_id,
                            value: 1
                        };
                        this.addSpec(payload);
                        this.specs[i].value = "1";
                        this.specsOfCategory.unshift(this.specs[i]);
                        return;
                    } else if (this.specs[i].type === "text") {
                        this.activePrompt = true;
                        return;
                    }
                }
            }
        },
        getInputValue() {
            let payload = {
                category_id: this.category_id,
                spec_id: this.spec_id,
                value: this.val
            };

            for (let i in this.specs) {
                if (this.specs[i].id === this.spec_id) {
                    this.specs[i].value = this.val;
                    this.specsOfCategory.unshift(this.specs[i]);
                    break;
                }
            }

            this.addSpec(payload);
        },
        addSpec(payload) {
            this.$vs.loading();

            this.$httpCars
                .post("categories/addspec", payload)
                .then(r => {
                    this.$vs.loading.close();
                    console.log(r);
                    this.$vs.notify({
                        title: "Success",
                        text: "Spec is added successfully!",
                        color: "success"
                    });
                    this.val = "";
                })
                .catch(() => {
                    this.$vs.loading.close();
                    //console.log(e);
                });
        },
        getValue(value) {
            if (value === "1") {
                return "True";
            } else if (value === "0") {
                return "False";
            }
            return value;
        },
        setBrandId(id) {
            this.brand_id = id;
        },
        setModelId(id) {
            this.model_id = id;
        },
        getCategories() {
            this.$vs.loading();
            this.$httpCars
                .get("categories")
                .then(r => {
                    this.$vs.loading.close();
                    this.categories = r.data.data.categories;
                    console.log({ categories: this.categories });
                })
                .catch(() => {
                    this.$vs.loading.close();
                    //console.log(e);
                });
        },
        getSpecs() {
            this.$httpCars
                .get("specs")
                .then(r => {
                    let totalSpecs = r.data.data.total;
                    this.$httpCars
                        .get(`specs?per_page=${totalSpecs}`)
                        .then(r => {
                            this.specs = r.data.data.data;
                            //console.log({ specs: this.specs });
                        })
                        .catch(() => {
                            //console.log(e);
                        });
                })
                .catch(() => {
                    //console.log({ error: e });
                });
        }
    },
    computed: {
        invalidSpec() {
            return this.spec_id === "0";
        },
        currentPage() {
            if (this.isMounted) {
                return this.$refs.table.currentx;
            }
            return 0;
        },
        queriedItems() {
            return this.$refs.table
                ? this.$refs.table.queriedResults.length
                : this.items.length;
        }
    },
    watch: {
        model_id(v) {
            this.categoriesOfModel = [];
            this.category_id = "0";
            for (let i in this.categories) {
                if (v === this.categories[i].model.id) {
                    this.categoriesOfModel.push(this.categories[i]);
                    console.log({ category: this.categories[i] });
                }
            }
        },
        category_id(v) {
            if (v === "0" || v === "") {
                this.invalidCategory = true;
                this.spec_id = "0";
            } else {
                this.invalidCategory = false;
                this.specsOfCategory = [];
                for (let i in this.categories) {
                    if (v === this.categories[i].id) {
                        this.specsOfCategory = this.categories[i].specs;
                        break;
                    }
                }
                console.log({ specsOfCategory: this.specsOfCategory });
            }
        },
        page() {
            this.getData();
        },
        query() {
            this.getData();
        },
        sortKey() {
            this.getData();
        },
        sortActive() {
            this.getData();
        },
        itemsPerPage() {
            this.getData();
        }
    },
    mounted() {
        this.getSpecs();
        this.getCategories();
        this.isMounted = true;
    }
};
</script>

<style scoped lang="scss">
.main {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

label {
    font-size: 1rem;
    padding: 5px;
}

select {
    padding: 10px;
    border-radius: 5px;
    width: 90%;
    background-color: #fff;
    border: 1px solid #00000030;
}

.spec {
    margin-top: 20px;
}

.flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
</style>
